import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { Trans } from "react-i18next";
import Container from "../../components/Container";
import Layout from "../../components/Layout";
import LinkButton from "../../components/LinkButton";
import Seo from "../../components/Seo";
import SimpleCarousel from "../../components/SimpleCarousel";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "page-ristorante"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function Ristorante() {
    const { t } = useTranslation();

    // Define common properties reusable for each image of carousel
    const sharedCarouselImageProperties = {
        aspectRatio: 16 / 10,
        className: "rounded-4xl",
        layout: "fullWidth",
        transformOptions: {
            fit: "cover",
        },
    };
    const images = [
        <StaticImage
            key={0}
            src="../../../assets/images/sala-pranzo/panoramica.jpg"
            alt="Panoramica"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={1}
            src="../../../assets/images/sala-pranzo/tavoli-larghi.jpg"
            alt="Tavoli"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={2}
            src="../../../assets/images/sala-pranzo/tavoli-larghi-1.jpg"
            alt="Tavoli"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={3}
            src="../../../assets/images/sala-pranzo/dettaglio-tavolo-grande.jpg"
            alt="Dettaglio tavolo grande"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={4}
            src="../../../assets/images/cibo/buffet-colazione.jpg"
            alt="Buffet colazione"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={5}
            src="../../../assets/images/cibo/dettaglio-buffet-colazione.jpg"
            alt="Dettaglio buffet colazione"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={6}
            src="../../../assets/images/cibo/dolci-buffet-colazione.jpg"
            alt="Dolci buffet colazione"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={7}
            src="../../../assets/images/cibo/cornetti-colazione.jpeg"
            alt="Cornetti colazione"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={8}
            src="../../../assets/images/cibo/umbricelli-tartufo.jpg"
            alt="Umbricelli al tartufo"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={9}
            src="../../../assets/images/cibo/pollo-alle-mandorle.jpeg"
            alt="Pollo alle mandorle"
            {...sharedCarouselImageProperties}
        />,
    ];

    return (
        <Layout>
            <Seo title={t("page-ristorante:theRestaurant")} />
            <Container>
                <SimpleCarousel images={images} />
                <h1 className="mt-8">{t("page-ristorante:theRestaurant")}</h1>
                <p>
                    <Trans
                        i18nKey="page-ristorante:description:1"
                        t={t}
                        components={{ bold: <b /> }}
                    />
                </p>
                <p>
                    <Trans i18nKey="page-ristorante:description:2" t={t} />
                </p>
                <p>
                    <Trans i18nKey="page-ristorante:description:3" t={t} />
                </p>
                <p>
                    <Trans
                        i18nKey="page-ristorante:description:4"
                        t={t}
                        components={{ bold: <b /> }}
                    />
                </p>
                <p>
                    <Trans i18nKey="page-ristorante:description:5" t={t} />
                </p>
                <div className="my-6 text-center">
                    <LinkButton
                        to="/ristorante/menu"
                        text={t("exploreTheMenu")}
                    />
                </div>
            </Container>
        </Layout>
    );
}
